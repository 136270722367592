/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import Cookies from 'js-cookie'
import { Moon, Sun } from 'lucide-react'
import { useTheme } from 'next-themes'
import { useEffect, useState } from 'react'
import useStrings from '~/hooks/useStrings'

export const ThemeSwitcher = () => {
  const [mounted, setMounted] = useState(false)
  const { theme, setTheme } = useTheme()
  const { t } = useStrings()

  useEffect(() => {
    setMounted(true)
  }, [])

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    // Update cookie
    Cookies.set('theme', newTheme)
    // Update next-themes
    setTheme(newTheme)
    // Manually update the AG Grid theme attribute
    document.documentElement.setAttribute('data-ag-theme-mode', `${newTheme}-mpt`)
    document.documentElement.setAttribute('class', newTheme === 'dark' ? 'dark-gradient-bg' : 'light-gradient-bg')
  }

  if (!mounted) return null
  return (
    <div>
      <button
        onClick={toggleTheme}
        aria-label={theme === 'light' ? t('Switch to dark theme') : t('Switch to light theme')}
        aria-pressed={theme === 'dark'}
        className="p-2 rounded-full hover:bg-default-100 transition-colors"
      >
        {theme === 'light' && <Sun size={20} aria-hidden="true" className="text-secondary" />}
        {theme === 'dark' && <Moon size={20} aria-hidden="true" className="text-secondary" />}
      </button>
    </div>
  )
}
