/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import React from 'react'
import { useParams } from 'react-router'
import useStrings from '~/hooks/useStrings'
import { UserData } from '~/lib/types'
import { NavigationBar } from './NavigationBar'
interface LayoutProps {
  userData: UserData
  padding: number
  children: React.ReactNode
}
export const Layout = ({ userData, padding, children }: LayoutProps) => {
  const { lang } = useParams()
  const { translations, t } = useStrings()
  return (
    <div className="min-h-screen flex flex-col">
      <header>
        <NavigationBar userData={userData} currentLang={lang || 'en'} translations={translations} />
      </header>
      <main className={`container mx-auto max-w-7xl flex-grow py-${padding}`}>{children}</main>
      <footer className="w-full flex items-center justify-center py-3 h-16">
        <p className="text-primary">{t(`© ${new Date().getFullYear()} My Piano Trainer`)}</p>
      </footer>
    </div>
  )
}
