/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuToggle } from '@heroui/react'
import { link as linkStyles } from '@heroui/theme'
import clsx from 'clsx'
import { useFeature } from 'flagged'
import { useTheme } from 'next-themes'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, useLocation, useNavigation, useParams } from 'react-router'
import { TranslationMap } from '~/hooks/types'
import useStrings from '~/hooks/useStrings'
import { UserData } from '~/lib/types'
import LanguageSelector from './LanguageSelector'
import { ThemeSwitcher } from './ThemeSwitcher'

interface NavLink {
  label: string
  user: boolean
  admin: boolean
  href: string
}
interface NavigationBarProps {
  userData: UserData
  currentLang: string
  translations: TranslationMap
}
const NavLink = React.memo(
  ({
    to,
    children,
    className,
    onClick,
  }: {
    to: string
    children: React.ReactNode
    className?: string
    onClick?: () => void
  }) => {
    const location = useLocation()
    const { lang } = useParams()
    const isActive = location.pathname === to

    return (
      <Link
        to={`/${lang}${to}`}
        className={clsx(
          className,
          'navigate-link transition-colors duration-200',
          linkStyles({ color: 'foreground' }),
          {
            'text-primary font-medium': isActive,
          },
        )}
        aria-current={isActive ? 'page' : undefined}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  },
)
export const NavigationBar = React.memo(
  ({ userData }: NavigationBarProps) => {
    const { t } = useStrings()
    const { theme } = useTheme()
    const hasV1 = useFeature('v1')
    const navigation = useNavigation()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const closeMenu = useCallback(() => {
      setIsMenuOpen(false)
    }, [])

    const loginItem = useMemo(
      () =>
        !userData.email && (
          <NavbarItem>
            <NavLink to="/login" onClick={closeMenu} aria-label={t('Sign in or register')}>
              {t('Login or Register')}
            </NavLink>
          </NavbarItem>
        ),
      [userData.email, closeMenu],
    )

    const languageItem = useMemo(
      () => (
        <NavbarItem className="sm:flex">
          <LanguageSelector />
        </NavbarItem>
      ),
      [],
    )

    const themeItem = useMemo(
      () => (
        <NavbarItem>
          <ThemeSwitcher />
        </NavbarItem>
      ),
      [theme],
    )

    const navItems = useMemo(() => {
      const navLinks: NavLink[] = [
        {
          label: 'Admin',
          user: true,
          admin: true,
          href: `/admin`,
        },
        {
          label: 'Account',
          user: true,
          admin: false,
          href: `/account`,
        },
      ]
      return navLinks
        .filter(i => (!i.user || (i.user && userData.email)) && ((!i.admin && hasV1) || userData.isAdmin))
        .map(item => (
          <NavbarItem key={item.href}>
            <NavLink to={item.href} onClick={closeMenu}>
              {t(item.label)}
            </NavLink>
          </NavbarItem>
        ))
    }, [userData.isAdmin, hasV1, closeMenu])

    const handleMenuOpenChange = useCallback(
      (isOpen: boolean) => {
        if (navigation.state === 'idle') {
          setIsMenuOpen(isOpen)
        }
      },
      [navigation.state],
    )

    return (
      <Navbar
        maxWidth="xl"
        position="sticky"
        className="w-full h-16 max-w-[100vw] overflow-x-hidden bg-transparent backdrop-blur-sm supports-[backdrop-filter]:bg-background/20"
        // className="w-full h-16 max-w-[100vw] overflow-x-hidden"
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={handleMenuOpenChange}
      >
        <NavbarContent className="sm:basis-full" justify="start">
          <NavbarBrand className="gap-3 max-w-fit">
            <NavLink to="/" className="flex justify-start gap-1" aria-label={t('Home')}>
              <img src="/images/logo-3-trans.png" alt="My Piano Trainer" className="w-12 h-12" aria-hidden="true" />
              <p className="hidden md:flex font-bold text-inherit">{t('My Piano Trainer')}</p>
            </NavLink>
          </NavbarBrand>
          <ul className="hidden sm:flex gap-4 justify-start ml-2">{...hasV1 ? navItems : []}</ul>
        </NavbarContent>

        <NavbarContent className="flex basis-1/5 transition-opacity duration-200" justify="end">
          <div className="hidden sm:flex">{loginItem}</div>
          <div className="hidden sm:flex">{languageItem}</div>
          {themeItem}
          <NavbarMenuToggle
            className="sm:hidden"
            aria-label={isMenuOpen ? t('Close menu') : t('Open menu')}
            aria-expanded={isMenuOpen}
          />
        </NavbarContent>

        <NavbarMenu aria-label={t('Mobile navigation menu')}>
          <div className="mx-4 mt-2 flex flex-col gap-2">{hasV1 ? [...navItems, loginItem, languageItem] : []}</div>
        </NavbarMenu>
      </Navbar>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.userData === nextProps.userData &&
      prevProps.currentLang === nextProps.currentLang &&
      prevProps.translations === nextProps.translations
    )
  },
)
NavigationBar.displayName = 'NavigationBar'
NavLink.displayName = 'NavLink'
