/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { Route } from '.react-router/types/app/routes/+types/_layout.$lang'
import { data, Outlet, useLoaderData, useLocation } from 'react-router'
import { Layout } from '~/components/layout'
import userDataSet from '~/services/UserService.server'

export async function loader({ request }: Route.LoaderArgs) {
  const uds = await userDataSet(request)
  return data(
    {
      userData: uds.userData,
    },
    {
      headers: {
        'Set-Cookie': await uds.cookieHeader(),
      },
    },
  )
}
export default function BaseLayout() {
  const { userData } = useLoaderData<typeof loader>()
  const location = useLocation()

  const padding = location.pathname.includes('exercise') ? 2 : 8

  return (
    <Layout userData={userData} padding={padding}>
      <Outlet />
    </Layout>
  )
}
