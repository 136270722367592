/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { Select, SelectItem } from "@heroui/react"
import { useParams } from 'react-router'
import useStrings from '~/hooks/useStrings'

export default function LanguageSelector() {
  const { t, languages } = useStrings()
  const { lang } = useParams()
  return (
    <Select
      aria-label={t('Language selector')}
      defaultSelectedKeys={[lang || 'en']}
      className="w-32 [&_span]:writing-mode-horizontal-tb"
      disallowEmptySelection={true}
      onChange={e => {
        const newLang = e.target.value
        if (newLang && newLang !== lang) {
          const newUrl = window.location.pathname.replace(`/${lang}`, `/${newLang}`)
          window.location.assign(newUrl)
        }
      }}
    >
      {languages.map(l => (
        <SelectItem key={l.language} value={l.language}>
          {l.nameNative}
        </SelectItem>
      ))}
    </Select>
  )
}
